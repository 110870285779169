import React from 'react';
import {Helmet} from 'react-helmet';
import Loader from '../components/Loader';
import Sidebar from '../layouts/Sidebar';
import FormatDate from '../components/FormatDate';
import WpRadioAinKarim from '../apis/WpRadioAinKarim';
import Player from '../components/Player';


class Post extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loaded: false,
            audio: '',
            author: '',
            content: '',
            date: '',
            imageSrc: '',
            title: '',
        }
    }

    componentDidMount() {
        this.getContent();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.match.url !== this.props.match.url) {
            this.getContent();
            this.setState({
                imageSrc: '',
                author: '',
                loaded: false,
            })
        }
    }

    getContent = async () => {
        const reg = /(\w+)([^/])+$/gi;
        const result = reg.exec(this.props.match.url);
        const slug = result[0];

        const resp = await WpRadioAinKarim.get('/posts', {
            params: {
                slug: slug,
            }
        });

        if (resp.data[0].featured_media)
            this.getImage(resp.data[0].featured_media);

        this.getAuthor(resp.data[0].author);

        this.setState({
            loaded: true,
            audio: resp.data[0].acf,
            content: resp.data[0].content.rendered,
            date: resp.data[0].date,
            title: resp.data[0].title.rendered,
        });

        window.scrollBy(0, -window.innerHeight);
        this.loadTwitch()
    }

    getImage = async (imageID) => {
        const resp = await WpRadioAinKarim.get(`/media/${imageID}`);
        this.setState({ imageSrc: resp.data.source_url });
    }

    getAuthor = async (authorID) => {
        const resp = await WpRadioAinKarim.get(`/users/${authorID}`);
        this.setState({ author: resp.data.name });
    }

    loadTwitch = () => {
        if(document.getElementById('twitch-embed') && window.Twitch !== undefined) {
            new window.Twitch.Embed("twitch-embed", {
                width: '100%',
                height: 480,
                channel: "jzieba20",
                // Only needed if this page is going to be embedded on other websites
                // parent: ["embed.example.com", "othersite.example.com"]
            });
        }
    }

    render() {
        const { loaded, audio, author, content, date, imageSrc, title } = this.state;
    
        return (
            <section className="section-padding post">
                <Helmet>
                    <title>{title} - Radio Ain Karim</title>
                </Helmet>
                <div className="container">
                    <div className="row">
                        <div className="col-12 col-lg-8">
                            {!loaded ? <Loader /> : (
                                <div className="content">
                                    <img src={imageSrc} alt="Zdjecie" />
                                    <div className="info"><span className="accent-color">{author}</span> | <FormatDate date={date} /></div>
                                    <h2 className="title">{title}</h2>

                                    <div dangerouslySetInnerHTML={{
                                        __html: content
                                    }} />

                                    {!!audio.length && <Player audio={audio} />}
                                </div>
                            )}
                        </div>

                        <div className="col-12 col-lg-4">
                            <Sidebar />
                        </div>

                    </div>

                </div>
            </section>

        );
    }
}

export default Post;