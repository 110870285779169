import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Statement from './Statement';


class ContactForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            firstLastName: '',
            email: '',
            subject: '',
            message: '',
            agreement: false,
            sending: false,
            statement: '',

            errors: {
                firstLastName: false,
                email: false,
                subject: false,
                message: false,
                agreement: false,
            }
        }
    }

    messages = {
        firstLastName_incorrect: 'Podaj minimum 5 znaków, maksymalnie 80 znków, bez cyfr',
        email_incorrect: 'Podaj prawidłowy adres email',
        subject_incorrect: 'Podaj minimum 5 znaków, maksymalnie 80 znaków, bez cyfr',
        message_incorrect: 'Podaj minimum 10 znaków, maksymalnie 5000 znaków',
        agreement_incorrect: 'Zaakceptuj politykę prywatności, jeśli zgadzasz się na wysłanie tej wiadomości.',
    }

    patterns = {
        email: '^[^\\s@]+@[^\\s@]+\\.[^\\s@]+$',
        text: '[a-zA-ZąĄććęęłŁńŃóÓśŚżŻŹŹ ]+',
        textarea: '.+',
    }

    handleChange = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        const type = e.target.type;

        if (type === "text" || type === "email" || type === "textarea") {
            this.inputValidation(name, value, type);
            this.setState({
                [name]: value,
            })
        } else if (type === "checkbox") {
            let errors = this.state.errors;
            errors.agreement = this.state.agreement;

            this.setState(prevState => ({
                agreement: !prevState.agreement,
                errors
                }))
        }
    }

    inputValidation = (name, value, type) => {
        let errors = this.state.errors;

        if (type === 'text') {

            const textReg = new RegExp(this.patterns.text, "gi");
            if (value.length > 4 && value.length <= 80 && textReg.test(value))
                errors[name] = false;
            else errors[name] = true;

        } else if (type === 'textarea') {

            const textareaReg = new RegExp(this.patterns.textarea, "gi");
            if (value.length > 10 && value.length <= 5000 && textareaReg.test(value))
                errors[name] = false;
            else errors[name] = true;

        } else if (type === 'email') {

            const emailReg = new RegExp(this.patterns.email, "gi");
            if (value.length > 10 && value.length <= 60 && emailReg.test(value))
                errors[name] = false;
            else errors[name] = true;

        } else if (type === 'checkbox')
            this.state.agreement ? errors.agreement = false : errors.agreement = true;

        this.setState({ errors });
    }

    formValidation = () => {

        this.inputValidation('firstLastName', this.state.firstLastName, 'text');
        this.inputValidation('subject', this.state.subject, 'text');
        this.inputValidation('email', this.state.email, 'email');
        this.inputValidation('message', this.state.message, 'textarea');
        this.inputValidation('agreement', this.state.agreement, 'checkbox');

        return !this.state.errors.firstLastName && !this.state.errors.subject && !this.state.errors.email && !this.state.errors.message;
    }

    handleSubmit = (e) => {
        e.preventDefault();
        const { firstLastName, email, subject, message, agreement } = this.state;

        if (this.formValidation() && agreement) {
            const url = "https://ainkarim-api.vercel.app/v1/api/contact";

            const data = {
                "firstLastName": firstLastName,
                "subject": subject,
                "email": email,
                "message": message,
                "agreement": Number(agreement)
            };

            const JSONdata = JSON.stringify(data);

            fetch(url, {
                method: 'POST',
                headers: {'Content-Type': 'application/json'},
                body: JSONdata
            }).then(res => res.json())
                .then(response => {
                    this.setState({
                        sending: false,
                        statement: 'Wiadomość została wysłana poprawnie',
                    });
                })
                .catch(error => {
                    this.setState({
                        statement: 'Wysłanie wiadomości nie powiodło się',
                    })
                })
                .finally(()=> {
                    setTimeout(() => {
                        this.setState({
                            statement: '',
                        });
                    }, 10000);
                });

            this.setState({
                firstLastName: '',
                email: '',
                subject: '',
                message: '',
                agreement: false,
                sending: true,
                statement: 'Wysyłanie wiadomości',

                errors: {
                    firstLastName: false,
                    email: false,
                    subject: false,
                    message: false,
                    agreement: false,
                }
            })
        }
    }

    render() {
        const { firstLastName, email, subject, message, agreement } = this.state.errors;
        const { firstLastName_incorrect, subject_incorrect, email_incorrect, message_incorrect, agreement_incorrect } = this.messages;

        return (
            <>
                {this.state.statement.length ? <Statement statement={this.state.statement} sending={this.state.sending} /> :
                    <form className="form" onSubmit={this.handleSubmit} noValidate>
                        <label className="form-item" htmlFor="firstLastName">
                            <input type="text" name="firstLastName" placeholder="Imię i nazwisko" value={this.state.firstLastName} onChange={this.handleChange} 
                            className={`${firstLastName ? 'error' : ''}`} />
                            {firstLastName && <div className="form-error-msg">{firstLastName_incorrect}</div>}
                        </label>

                        <label className="form-item" htmlFor="email">
                            <input type="email" name="email" placeholder="Adres email" value={this.state.email} onChange={this.handleChange}
                            className={`${email ? 'error' : ''}`} />
                            {email && <div className="form-error-msg">{email_incorrect}</div>}
                        </label>

                        <label className="form-item" htmlFor="subject">
                            <input type="text" name="subject" placeholder="Temat wiadomości" value={this.state.subject} onChange={this.handleChange}
                            className={`${subject ? 'error' : ''}`} />
                            {subject && <div className="form-error-msg">{subject_incorrect}</div>}
                        </label>

                        <label className="form-item" htmlFor="message">
                            <textarea name="message" id="text" cols="30" rows="10" value={this.state.message} onChange={this.handleChange} 
                            className={`${message ? 'error' : ''}`} />
                            {message && <div className="form-error-msg">{message_incorrect}</div>}
                        </label>

                        <label className="item-agreement" htmlFor="agreement">
                            <input type="checkbox" name="agreement" checked={this.state.agreement} onChange={this.handleChange} />
                            <p>
                                Zgodnie z <Link to="/polityka-prywatnosci" target="_blank" title="Polityka prywatności">polityką prywatności</Link>. Wyrażam zgodę na przetwarzanie moich danych osobowych
                                {agreement && <span className="form-error-msg">{agreement_incorrect}</span>}
                            </p>

                        </label>

                        <button className="btn-form" onSubmit={this.handleSubmit}>Wyślij wiadomość</button>
                    </form>}
            </>
        );
    }
}

export default ContactForm;