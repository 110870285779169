import React, { Component } from 'react';
import RadioLiveHeader from './RadioStreamHeader';
import RadioStreamAside from './RadioStreamAside';

class RadioStream extends Component {
    constructor(props){
        super(props);
        this.state={
            radioStreamActive: false,
        }
    }

    render() {
        const { radioStreamActive } = this.state;
        return (
            <div className="fade">
                <RadioLiveHeader
                    streamActive={radioStreamActive}
                />

                <div className="player-aside">
                    <RadioStreamAside
                        streamActive={radioStreamActive}
                    />
                </div>
            </div>
        );
    }
}

export default RadioStream;