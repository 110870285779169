import React, { Component } from 'react';
import WpRadioAinKarim from '../apis/WpRadioAinKarim';

const dayOfWeek = ['Poniedziałek', 'Wtorek', 'Środa', 'Czwartek', 'Piątek', 'Sobota', 'Niedziela'];

class ProgramList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            programs: null,
            program: null,
            currentDay: '',
            reduceActive: true,
        }
    }
    componentDidMount() {
        this.getCurrentDay();
        this.getPrograms();
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.currentDay !== this.state.currentDay) {
            document.querySelectorAll('.btn-nav').forEach(btn => {
                if (String(this.state.currentDay) === btn.name) {
                    btn.classList.add('accent-color');
                }
            });

            if(this.state.programs) {
                this.getProgram();
            }
        }

        if(prevState.programs !== this.state.programs) {
            this.getProgram();
        }
    }

    getCurrentDay = () => {
        const currentDay = new Date().getUTCDay();

        this.setState({
            currentDay,
        })
    }

    getPrograms = async () => {
        const resp = await WpRadioAinKarim.get('/pages/9', {
            params: {
              status: 'publish'
            }
          });

        this.setState({
            programs: resp.data.acf.program
        })
    }

    getProgram = () => {
        const program = this.state.programs.find(item => this.state.currentDay !== 0 ? item.day_id === String(this.state.currentDay) : item.day_id === '7')
        
        this.setState({
            program
        })
    }

    handleChangeDay = (e) => {
        const elem = e.target;
        document.querySelectorAll('.btn-nav').forEach(btn => btn.classList.remove('accent-color'));
        elem.classList.add('accent-color');

        this.setState({
            currentDay: String(elem.name),
        })
    }

    handleClick = () => {
        this.setState(prevState => ({ reduceActive: !prevState.reduceActive }));
    }

    render() {
        const buttons = dayOfWeek.map((item, index) =>
            <button
                key={index}
                name={index + 1}
                className="btn-nav"
                onClick={this.handleChangeDay}
            >{item}
            </button>)

        const program = this.state.program && this.state.program.program_list.map((item, index) => (
            <tr key={index}>
                <td className="program-time">
                    <span>{`${item.start_time.substr(0, 5)} - ${item.end_time.substr(0, 5)}`}</span>
                </td>
                <td>{item.title}</td>
            </tr>
        ));

        const isReduces = this.state.program && this.state.program.program_list.length < 17;


        const { reduceActive } = this.state;

        return (
            <>
                <div className="program-list">
                    <div className="btn-nav-wrapper">
                        {buttons}
                    </div>

                    <div className={reduceActive ? "reduce-height" : null}>
                        <table className="table table-sm">
                            <tbody>
                                {program}
                            </tbody>
                        </table>
                    </div>
                </div>
                {!isReduces && 
                <button className="btn btn-1" title="Pokaż/Schowaj program" onClick={this.handleClick}>{reduceActive ? 'Pokaż program' : 'Schowaj program'}</button>}
            </>
        )
    }
}

export default ProgramList;